<template>
  <div class='card'>
    <h5 class='font-bold hr-line-dashed-bottom'>{{ t['472'] }}</h5>
<!--    <h5 class='font-bold hr-line-dashed-bottom'>{{ someDate }}</h5>-->
    <div class='p-fluid grid hr-line-dashed-bottom mt-2'>
      <div class="field col-12 md:col-2">
        <span class="p-float-label">
          <InputText id="tidInput" type="text" :disabled='true' v-model='tid'/>
          <label for="tidInput" class='font-bold'>{{ t['460'] }}</label>
        </span>
      </div>
      <div class="field col-12 md:col-2">
        <span class="p-float-label">
          <InputText id="midInput" type="text" :disabled='true' v-model='mid'/>
          <label for="midInput" class='font-bold'>{{ t['473'] }}</label>
        </span>
      </div>
      <div class="field col-12 md:col-3">
        <span class="p-float-label">
          <InputText id="nameInput" type="text" v-model='nameValue'/>
          <label for="nameInput" class='font-bold'>{{ t['116'] }}</label>
        </span>
      </div>
      <div class="field col-12 md:col-5"></div>
        <Divider align='left' type='dashed'>
            <p>{{ t['474'] }}</p>
        </Divider>
        <div class="field col-12 md:col-1 ml-2" style='margin-bottom: 0px;'>
            <div class="field-checkbox md:col-1">
                <Checkbox id='mnun1' v-model='mnum1' :binary='true'></Checkbox>
                <label for='mnun1' v-bind:class="[!mnum1 ? 'font-light' : 'font-bold']">{{ t['475'] }}</label>
            </div>
        </div>
        <div class="field col-12 md:col-2">
            <span class="p-float-label">
                <InputNumber id="min-num1" v-model="lnum1" mode="decimal" showButtons :min="0" :max="100" :step='0.001' class='ml-2' :disabled="!mnum1"/>
                <label for="min-num1" v-bind:class="[!mnum1 ? 'font-light' : 'font-bold']">{{ t['483'] }}</label>
            </span>
        </div>
        <div class="field col-12 md:col-2">
            <span class="p-float-label">
                <InputNumber id="max-num1" v-model="hnum1" mode="decimal" showButtons :min="0" :max="100" :step='0.001' class='ml-2' :disabled="!mnum1"/>
                <label for="max-num1" v-bind:class="[!mnum1 ? 'font-light' : 'font-bold']">{{ t['484'] }}</label>
            </span>
        </div>
        <div class='field col-12 md:col-6'></div>
        <div class="field col-12 md:col-1 ml-2" style='margin-bottom: 0px;'>
            <div class="field-checkbox md:col-2">
                <Checkbox id='mnun2' v-model='mnum2' :binary='true'></Checkbox>
                <label for='mnun2' v-bind:class="[!mnum2 ? 'font-light' : 'font-bold']">{{ t['476'] }}</label>
            </div>
        </div>
        <div class="field col-12 md:col-2">
            <span class="p-float-label">
                <InputNumber id="min-num2" v-model="lnum2" mode="decimal" showButtons :min="0" :max="100" :step='0.001' class='ml-2' :disabled="!mnum2"/>
                <label for="min-num2" v-bind:class="[!mnum2 ? 'font-light' : 'font-bold']">{{ ' ' + t['483'] }}</label>
            </span>
        </div>
        <div class="field col-12 md:col-2">
            <span class="p-float-label">
                <InputNumber id="max-num2" v-model="hnum2" mode="decimal" showButtons :min="0" :max="100" :step='0.001' class='ml-2' :disabled="!mnum2"/>
                <label for="max-num2" v-bind:class="[!mnum2 ? 'font-light' : 'font-bold']">{{ ' ' + t['484'] }}</label>
            </span>
        </div>
        <Divider align='left' type='dashed'>
            <p>{{ t['490'] }}</p>
        </Divider>
        <div class="field col-12 md:col-1 ml-2" style='margin-bottom: 0px;'>
            <div class="field-checkbox md:col-1">
                <Checkbox id='mnun3' v-model='mnum3' :binary='true'></Checkbox>
                <label for='mnun3' v-bind:class="[!mnum3 ? 'font-light' : 'font-bold']">{{ t['477'] }}</label>
            </div>
        </div>
        <div class="field col-12 md:col-2">
            <span class="p-float-label">
                <InputNumber id="min-num3" v-model="lnum3" showButtons :min="0" :max="100" class='ml-2' :disabled="!mnum3"/>
                <label for="min-num3" v-bind:class="[!mnum3 ? 'font-light' : 'font-bold']">{{ ' ' + t['483'] }}</label>
            </span>
        </div>
        <div class="field col-12 md:col-2">
            <span class="p-float-label">
                <InputNumber id="max-num3" v-model="hnum3" showButtons :min="0" :max="100" class='ml-2' :disabled="!mnum3"/>
                <label for="max-num3" v-bind:class="[!mnum3 ? 'font-light' : 'font-bold']">{{ ' ' + t['484'] }}</label>
            </span>
        </div>
        <div class='field col-12 md:col-6'></div>
        <div class="field col-12 md:col-1 ml-2" style='margin-bottom: 0px;'>
            <div class="field-checkbox md:col-2">
                <Checkbox id='mnun4' v-model='mnum4' :binary='true'></Checkbox>
                <label for='mnun4' v-bind:class="[!mnum4 ? 'font-light' : 'font-bold']">{{ t['478'] }}</label>
            </div>
        </div>
        <div class="field col-12 md:col-2">
            <span class="p-float-label">
                <InputNumber id="min-num4" v-model="lnum4" showButtons :min="0" :max="100" class='ml-2' :disabled="!mnum4"/>
                <label for="min-num4" v-bind:class="[!mnum4 ? 'font-light' : 'font-bold']">{{ ' ' + t['483'] }}</label>
            </span>
        </div>
        <div class="field col-12 md:col-2">
            <span class="p-float-label">
                <InputNumber id="max-num4" v-model="hnum4" showButtons :min="0" :max="100" class='ml-2' :disabled="!mnum4"/>
                <label for="max-num4" v-bind:class="[!mnum4 ? 'font-light' : 'font-bold']">{{ ' ' + t['484'] }}</label>
            </span>
        </div>
        <Divider align='left' type='dashed'>
            <p>{{ t['491'] }}</p>
        </Divider>
        <div class="field col-12 md:col-1 ml-2" style='margin-bottom: 0px;'>
            <div class="field-checkbox md:col-2">
                <Checkbox id='mvac1' v-model='mvac1' :binary='true'></Checkbox>
                <label for='mvac1' v-bind:class="[!mvac1 ? 'font-light' : 'font-bold']">{{ t['479'] }}</label>
            </div>
        </div>
        <div class="field col-12 md:col-2">
            <span class="p-float-label">
                <InputNumber id="min-vac1" v-model="lvac1" showButtons :min="0" :max="100" class='ml-2' :disabled="!mvac1"/>
                <label for="min-vac1" v-bind:class="[!mvac1 ? 'font-light' : 'font-bold']">{{ ' ' + t['486'] }}</label>
            </span>
        </div>
        <div class="field col-12 md:col-2">
            <span class="p-float-label">
                <InputNumber id="max-vac1" v-model="hvac1" showButtons :min="0" :max="100" class='ml-2' :disabled="!mvac1"/>
                <label for="max-vac1" v-bind:class="[!mvac1 ? 'font-light' : 'font-bold']">{{ ' ' + t['485'] }}</label>
            </span>
        </div>
        <div class="field col-12 md:col-2">
            <span class="p-float-label">
                <InputText id="fvac1" v-model="fvac1" class='ml-2' :disabled="!mvac1"/>
                <label for="fvac1" v-bind:class="[!mvac1 ? 'font-light' : 'font-bold']">{{ ' ' + t['487'] }}</label>
            </span>
        </div>
        <div class='field col-12 md:col-4'></div>
        <div class="field col-12 md:col-1 ml-2" style='margin-bottom: 0px;'>
            <div class="field-checkbox md:col-2">
                <Checkbox id='mvac2' v-model='mvac2' :binary='true'></Checkbox>
                <label for='mvac2' v-bind:class="[!mvac2 ? 'font-light' : 'font-bold']">{{ t['480'] }}</label>
            </div>
        </div>
        <div class="field col-12 md:col-2">
            <span class="p-float-label">
                <InputNumber id="min-vac2" v-model="lvac2" showButtons :min="0" :max="100" class='ml-2' :disabled="!mvac2"/>
                <label for="min-vac2" v-bind:class="[!mvac2 ? 'font-light' : 'font-bold']">{{ ' ' + t['486'] }}</label>
            </span>
        </div>
        <div class="field col-12 md:col-2">
            <span class="p-float-label">
                <InputNumber id="max-vac2" v-model="hvac2" showButtons :min="0" :max="100" class='ml-2' :disabled="!mvac2"/>
                <label for="max-vac2" v-bind:class="[!mvac2 ? 'font-light' : 'font-bold']">{{ ' ' + t['485'] }}</label>
            </span>
        </div>
        <div class="field col-12 md:col-2">
            <span class="p-float-label">
                <InputText id="fvac2" v-model="fvac2" class='ml-2' :disabled="!mvac2"/>
                <label for="fvac2" v-bind:class="[!mvac2 ? 'font-light' : 'font-bold']">{{ ' ' + t['487'] }}</label>
            </span>
        </div>
        <Divider align='left' type='dashed'>
            <p>{{ t['492'] }}</p>
        </Divider>
        <div class="field col-12 md:col-1 ml-2" style='margin-bottom: 0px;'>
            <div class="field-checkbox md:col-2">
                <Checkbox id='mdat1' v-model='mdat1' :binary='true'></Checkbox>
                <label for='mdat1' v-bind:class="[!mdat1 ? 'font-light' : 'font-bold']">{{ t['481'] }}</label>
            </div>
        </div>
        <div class="field col-12 md:col-2">
            <span class="p-float-label">
                <Calendar id="min-dat1" v-model="ldat1" :showIcon='true' :dateFormat="t['dateFormat']" class='ml-2' :disabled="!mdat1"/>
                <label for="min-dat1" v-bind:class="[!mdat1 ? 'font-light' : 'font-bold']">{{ ' ' + t['488'] }}</label>
            </span>
        </div>
        <div class="field col-12 md:col-2">
            <span class="p-float-label">
                <Calendar id="max-dat1" v-model="hdat1" :showIcon='true' :dateFormat="t['dateFormat']" class='ml-2' :disabled="!mdat1"/>
                <label for="max-dat1" v-bind:class="[!mdat1 ? 'font-light' : 'font-bold']">{{ ' ' + t['489'] }}</label>
            </span>
        </div>
        <div class="field col-12 md:col-2">
            <span class="p-float-label">
                <InputText id="fdat1" v-model="fdat1" class='ml-2' :disabled="!mdat1"/>
                <label for="fdat1" v-bind:class="[!mdat1 ? 'font-light' : 'font-bold']">{{ ' ' + t['487'] }}</label>
            </span>
        </div>
        <div class='field col-12 md:col-4'></div>
        <div class="field col-12 md:col-1 ml-2" style='margin-bottom: 0px;'>
            <div class="field-checkbox md:col-2">
                <Checkbox id='mdat2' v-model='mdat2' :binary='true'></Checkbox>
                <label for='mdat2' v-bind:class="[!mdat2 ? 'font-light' : 'font-bold']">{{ ' ' + t['482'] }}</label>
            </div>
        </div>
        <div class="field col-12 md:col-2">
            <span class="p-float-label">
                <Calendar id="min-dat2" v-model="ldat2" :showIcon='true' :dateFormat="t['dateFormat']" class='ml-2' :disabled="!mdat2"/>
                <label for="min-dat2" v-bind:class="[!mdat2 ? 'font-light' : 'font-bold']">{{ ' ' + t['488'] }}</label>
            </span>
        </div>
        <div class="field col-12 md:col-2">
            <span class="p-float-label">
                <Calendar id="max-dat2" v-model="hdat2" :showIcon='true' :dateFormat="t['dateFormat']" class='ml-2' :disabled="!mdat2"/>
                <label for="max-dat2" v-bind:class="[!mdat2 ? 'font-light' : 'font-bold']">{{ ' ' + t['489'] }}</label>
            </span>
        </div>
        <div class="field col-12 md:col-2">
            <span class="p-float-label">
                <InputText id="fdat2" v-model="fdat2" class='ml-2' :disabled="!mdat2"/>
                <label for="fdat2" v-bind:class="[!mdat2 ? 'font-light' : 'font-bold']">{{ ' ' + t['487'] }}</label>
            </span>
        </div>
    </div>
    <div class='p-fluid grid hr-line-dashed-bottom mt-2'>
      <div class="field col-12 hr-line-bottom mt-2">
      <span class="p-float-label">
        <Textarea id='commentInput' v-model='comment' :autoResize='true' style='width: 100%;'></Textarea>
        <label for="commentInput" class='font-bold'>{{ t['447'] }}</label>
      </span>
      </div>
    </div>
      <div class='mt-2'>
          <Button class='p-button-primary mr-2' @click='updateModel'>{{ t['87'] }}</Button>
          <Button class='p-button-outlined' @click="backToTable">{{ t['86'] }}</Button>
      </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { usePrimeVue } from 'primevue/config';
import { computed } from 'vue';
import axios from 'axios';

export default {
  name: 'EditModel',
  setup() {
    const store = useStore()
    const primeVue = usePrimeVue()

    return {
      token: computed(() => store.state.bearerToken),
      t: primeVue.config.locale,
    }
  },
  data() {
    return {
      tid: this.$route.params.tid,
      mid: this.$route.params.mid,
      nameValue: null,
      someDate:null,
      mnum1: null,
      lnum1: null,
      hnum1: null,
      mnum2: null,
      lnum2: null,
      hnum2: null,
      mnum3: null,
      lnum3: null,
      hnum3: null,
      mnum4: null,
      lnum4: null,
      hnum4: null,
      mvac1: null,
      lvac1: null,
      hvac1: null,
      fvac1: null,
      mvac2: null,
      lvac2: null,
      hvac2: null,
      fvac2: null,
      mdat1: null,
      ldat1: null,
      hdat1: null,
      fdat1: null,
      mdat2: null,
      ldat2: null,
      hdat2: null,
      fdat2: null,
      comment: null,
    }
  },
  watch: {
    '$route'(to) {
      this.tid = to.params.tid
      this.mid = to.params.mid
    },
  },
  mounted() {
      let currentTimeZone = new Date().getTimezoneOffset()
      console.log(currentTimeZone) // -120/60
      // TODO: Matematična formula za računanje time zona (bo kar globalen service)
      this.someDate = new Date("2021-04-04T08:47:11.217371" + '-02:00' )

      this.getModel()
  },
  methods: {
      getModel() {
          axios
              .get( 'administration/param/table/'+ this.tid + '/model/' + this.mid + '/get', {
                  headers: {
                      Authorization: this.token
                  }
              })
              .then( res => {
                  if(res.status === 200 && res.data.rmsList[0].retCode === 0) {
                      let model = res.data.retObj
                      this.nameValue = model.name
                      this.mnum1 = model.mnum1
                      this.lnum1 = model.lnum1
                      this.hnum1 = model.hnum1
                      this.mnum2 = model.mnum2
                      this.lnum2 = model.lnum2
                      this.hnum2 = model.hnum2
                      this.mnum3 = model.mnum3
                      this.lnum3 = model.lnum3
                      this.hnum3 = model.hnum3
                      this.mnum4 = model.mnum4
                      this.lnum4 = model.lnum4
                      this.hnum4 = model.hnum4
                      this.mvac1 = model.mvac1
                      this.lvac1 = model.lvac1
                      this.hvac1 = model.hvac1
                      this.fvac1 = model.lvac1
                      this.mvac2 = model.mvac2
                      this.lvac2 = model.lvac2
                      this.hvac2 = model.hvac2
                      this.fvac2 = model.lvac2
                      this.mdat1 = model.mdat1
                      this.ldat1 = model.ldat1
                      this.hdat1 = model.hdat1
                      this.fdat1 = model.ldat1
                      this.mdat2 = model.mdat2
                      this.ldat2 = model.ldat2
                      this.hdat2 = model.hdat2
                      this.fdat2 = model.ldat2
                    this.comment = model.comment

                  } else {
                      this.$toast.add({severity:'error', summary: 'Error', detail:res.data.rmsList[0].retMsg, life: 3000});
                  }
              })
              .catch(error => {
                  console.log("There was an error loading model!");
                  console.log(error);
                  this.backToTable()
              })
      },
      updateModel() {
          axios
              .post('administration/param/table/'+ this.tid + '/model/' + this.mid + '/update', {
                  "tid": Number(this.tid),
                  "mid": Number(this.mid),
                  "name": this.nameValue,
                  "mnum1": this.mnum1,
                  "mnum2": this.mnum2,
                  "mnum3": this.mnum3,
                  "mnum4": this.mnum4,
                  "lnum1": this.lnum1,
                  "hnum1": this.hnum1,
                  "lnum2": this.lnum2,
                  "hnum2": this.hnum2,
                  "lnum3": this.lnum3,
                  "hnum3": this.hnum3,
                  "lnum4": this.lnum4,
                  "hnum4": this.hnum4,
                  "mvac1": this.mvac1,
                  "mvac2": this.mvac2,
                  "lvac1": this.lvac1,
                  "hvac1": this.hvac1,
                  "fvac1": this.fvac1,
                  "lvac2": this.lvac2,
                  "hvac2": this.hvac2,
                  "fvac2": this.fvac2,
                  "mdat1": this.mdat1,
                  "mdat2": this.mdat2,
                  "ldat1": this.ldat1,
                  "hdat1": this.hdat1,
                  "fdat1": this.fdat1,
                  "ldat2": this.ldat2,
                  "hdat2": this.hdat2,
                  "fdat2": this.fdat2,
                  "comment": this.comment,
              }, {
                  headers: {
                      Authorization: this.token
                  }
              })
              .then(res => {
                  if(res.status === 200 && res.data.rmsList[0].retCode === 0) {
                      setTimeout(() => {
                          this.$toast.add({severity:'success', summary: 'Success!', detail:res.data.rmsList[0].retMsg, life: 3000})
                      }, 500)
                      this.backToTable()
                  }else {
                      this.$toast.add({severity:'error', summary: 'Error', detail:res.data.rmsList[0].retMsg, life: 3000})
                  }
              })
              .catch(error  => {
                  console.log(error);
              })
      },
      backToTable() {
          this.$router.replace('/editParamTable/' + this.tid)
      },
  }
};
</script>

<style scoped>

</style>